export function createData(
  company_id,
  company_code,
  company_name,
  company_promotion,
  status
) {
  return { company_id, company_code, company_name, company_promotion, status };
}

export function addRow(valueObjArr) {
  return valueObjArr.map((item) => {
    return createData(
      item.company_id,
      item.company_code,
      item.company_name,
      item.company_promotion,
      item.status
    );
  });
}

export const headerTable = {
  company_id: "",
  company_code: "รหัสโครงการ",
  company_name: "ชื่อโครงการ",
  company_promotion: "โปร",
  status: "สถานะ",
};

export const headerOutstandingListTable = {
  checkbox: "เลือก",
  record_id: "ปุ่ม",
  card_code: "เลขบัตร",
  license_plate: "ทะเบียนรถ",
  home_address: "บ้านเลขที่",
  full_name: "ชื่อ-นามสกุล",
  parking_in_datetime: "เวลาเข้า",
};

export const getStatus = (status) => {
  switch (status) {
    case "NORMAL":
      return "สถานะพร้อมใช้งาน";
    case "EXPIRE":
      return "สถานะหมดอายุ";
    default:
      return "สถานะระงับการให้บริการ";
  }
};

export const headCells = [
  {
    id: "card_code",
    numeric: false,
    disablePadding: true,
    label: "เลขบัตร",
  },
  {
    id: "license_plate",
    numeric: false,
    disablePadding: false,
    label: "ทะเบียนรถ",
  },
  {
    id: "home_address",
    numeric: false,
    disablePadding: false,
    label: "บ้านเลขที่",
  },
  {
    id: "full_name",
    numeric: false,
    disablePadding: false,
    label: "ชื่อ-นามสกุล",
  },
  {
    id: "parking_in_datetime",
    numeric: false,
    disablePadding: false,
    label: "เวลาเข้า",
  },
];

export function createDataRow(id, item) {
  return {
    id,
    card_code: item.card_code,
    license_plate: item.license_plate,
    home_address: item.home_address,
    full_name: item.full_name,
    parking_in_datetime: item.parking_in_datetime,
  };
}
