import { setFetching, setSuccess, setFailed } from "actions/main/main.action";
import { MAIN_URL, OUTSTANDING_MULTIPLIE_CLEAR_API } from "constants/api-route";
import { httpClientPOSTMethodVerifyAuth } from "utils/httpClient.utils";
import {
    MESSAGE_COMPANYID_NOTFOUND,
    MESSAGE_RECORDS_NOTFOUND
} from "constants/message.constant";
import swal from "sweetalert";

const MultipliClearOutstandingActopn = (credential, authStore,refreshFunc) => {
  return async (dispatch) => {
    if (multiClearMiddleware(credential)) {
      dispatch(setFetching());
      const urlClient = `${MAIN_URL}${OUTSTANDING_MULTIPLIE_CLEAR_API}`;
      const valuesObj = {
        company_id : credential.company_id ? parseInt(credential.company_id) : 0,
        records_id: credential.records_id ? credential.records_id.map(item=>parseInt(item)) : []
      };
      const result = await httpClientPOSTMethodVerifyAuth({
        urlClient,
        valuesObj,
        authStore,
      });
      if (result.error) {
        dispatch(setFailed());
        swal("Warning!", result.message, "warning");
      } else {
        dispatch(setSuccess());
        swal("Success", "เคลียร์รถคงค้างสำเร็จ", "success").then(() => {
            refreshFunc();
        });
      }
    }
  };
};

function multiClearMiddleware(valuesObj) {
  if (!valuesObj.company_id) {
    swal("Warning!", MESSAGE_COMPANYID_NOTFOUND, "warning");
    return false;
  } else if (valuesObj.records_id.length == 0){
    swal("Warning!", MESSAGE_RECORDS_NOTFOUND, "warning");
    return false;
  }
  return true;
}

export default MultipliClearOutstandingActopn;
