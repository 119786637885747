export const SUCCESS_MESSAGE = 'เรียบร้อย'
export const FAILED_MESSAGE = 'ล้มเหลว'
export const CLEAR_VALUE_MESSAGE = 'Clear Data'
export const GET_DATA_SUCCESSFULLY = 'Get Data Successfully'

export const HTTP_FETCHING = 'HTTP_FETCHING'
export const HTTP_SUCCESS = 'HTTP_SUCCESS'
export const HTTP_FAILED = 'HTTP_FAILED'

export const LOGIN_FAILED_MESSAGE = 'Username หรือ Password ไม่ถูกต้อง !'
export const HTTP_LOGIN_CLEARVALUES = 'HTTP_LOGIN_CLEARVALUES'
export const HTTP_LOGIN_FETCHING = 'HTTP_LOGIN_FETCHING'
export const HTTP_LOGIN_SUCCESS = 'HTTP_LOGIN_SUCCESS'
export const HTTP_LOGIN_FAILED = 'HTTP_LOGIN_FAILED'

export const HTTP_LOGOUT_FETCHING = 'HTTP_LOGOUT_FETCHING'
export const HTTP_LOGOUT_SUCCESS = 'HTTP_LOGOUT_SUCCESS'

export const HTTP_SELECT_COMPANY_SUCCESS = 'HTTP_SELECT_COMPANY_SUCCESS'
export const HTTP_SELECT_COMPANY_CLEAR = 'HTTP_SELECT_COMPANY_CLEAR'

export const HTTP_GET_COMPANY_ALL_FETCHING = 'HTTP_GET_COMPANY_ALL_FETCHING'
export const HTTP_GET_COMPANY_ALL_SUCCESS = 'HTTP_GET_COMPANY_ALL_SUCCESS'
export const HTTP_GET_COMPANY_ALL_FAILED = 'HTTP_GET_COMPANY_ALL_FAILED'
export const HTTP_GET_COMPANY_ALL_CLEARSTORE = 'HTTP_GET_COMPANY_ALL_CLEARSTORE'
export const HTTP_GET_COMPANY_ALL_GETDATASUCCESS = 'HTTP_GET_COMPANY_ALL_GETDATASUCCESS'
export const HTTP_GET_COMPANY_LIST_ALL_SUCCESS = 'HTTP_GET_COMPANT_LIST_ALL_SUCCESS'
export const HTTP_GET_COMPANY_LIST_CLEAR = 'HTTP_GET_COMPANT_LIST_CLEAR'


export const HTTP_HOME_SELECT_COMPANY_SUCCESS = 'HTTP_HOME_SELECT_COMPANY_SUCCESS'
export const HTTP_HOME_CLEAR_COMPANY = 'HTTP_HOME_CLEAR_COMPANY'
export const HTTP_GET_HOME_ALL_SUCCESS = 'HTTP_GET_HOME_ALL_SUCCESS'
export const HTTP_GET_HOME_CLEAR = 'HTTP_GET_HOME_CLEAR'

export const HTTP_VILLAGER_SELECT_COMPANY_SUCCESS = 'HTTP_VILLAGER_SELECT_COMPANY_SUCCESS'
export const HTTP_GET_VILLAGER_ALL_SUCCESS = 'HTTP_GET_VILLAGER_ALL_SUCCESS'
export const HTTP_VILLAGER_CLEAR_COMPANY = 'HTTP_VILLAGER_CLEAR_COMPANY'
export const HTTP_GET_VILLAGER_CLEAR = 'HTTP_GET_VILLAGER_CLEAR'

export const HTTP_GET_PRIVILEGE_ALL_SUCCESS = 'HTTP_GET_PRIVILEGE_ALL_SUCCESS'
export const HTTP_GET_PRIVILEGE_CLEAR = 'HTTP_GET_PRIVILEGE_CLEAR'

export const HTTP_GET_USER_LIST_ALL_SUCCESS = 'HTTP_GET_USER_LIST_ALL_SUCCESS'
export const HTTP_GET_USER_LIST_CLEAR = 'HTTP_GET_USER_LIST_CLEAR'
export const HTTP_SELECT_USER_SUCCESS = 'HTTP_SELECT_USER_SUCCESS'
export const HTTP_SELECT_USER_CLEAR = 'HTTP_SELECT_USER_CLEAR'

export const HTTP_GET_SLOT_NOTUSE_SUCCESS = 'HTTP_GET_SLOT_NOTUSE_SUCCESS'
export const HTTP_GET_SLOT_NOTUSE_CLEAR = 'HTTP_GET_SLOT_NOTUSE_CLEAR'
export const HTTP_GET_SLOT_MAX_SUCCESS = 'HTTP_GET_SLOT_MAX_SUCCESS'
export const HTTP_GET_SLOT_MAX_CLEAR = 'HTTP_GET_SLOT_MAX_CLEAR'
export const HTTP_ADD_SLOT_SUCCESS = 'HTTP_ADD_SLOT_SUCCESS'
export const HTTP_ADD_SLOT_CLEAR = 'HTTP_ADD_SLOT_CLEAR'

export const HTTP_GET_OUTSTANDING_ALL_SUCCESS = 'HTTP_GET_OUTSTANDING_ALL_SUCCESS'
export const HTTP_GET_OUTSTANDING_ALL_CLEAR = 'HTTP_GET_OUTSTANDING_ALL_CLEAR'
export const HTTP_OUTSTANDING_SELECT_COMPANY_SUCCESS = 'HTTP_OUTSTANDING_SELECT_COMPANY_SUCCESS'
export const HTTP_OUTSTANDING_SELECT_COMPANY_CLEAR = 'HTTP_OUTSTANDING_SELECT_COMPANY_CLEAR'