import {
    HTTP_OUTSTANDING_SELECT_COMPANY_SUCCESS,
    HTTP_OUTSTANDING_SELECT_COMPANY_CLEAR,
} from 'constants/constants.utils'
export const setOutstandingSelectCompanySuccess = (payload) => ({
    type: HTTP_OUTSTANDING_SELECT_COMPANY_SUCCESS,
    payload
})

export const setOutstandingSelectCompanyClear = () => ({
    type: HTTP_OUTSTANDING_SELECT_COMPANY_CLEAR,
})



