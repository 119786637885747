import * as React from "react";
import * as moment from "moment";
import swal from 'sweetalert';
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { visuallyHidden } from "@mui/utils";
import { headCells } from "../data/OutstandingData";
import { useSelector, useDispatch, connect } from "react-redux";
import { useHistory } from "react-router-dom";
// component
import DateMaterialUi from "components/DateTime/DateMaterialUi";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// action
import { checkJWTTOKENAction } from "actions/main/main.action";
import {
  GetOutstandingAllAction,
  MultipliClearOutstandingActopn,
} from "actions/outstanding";
import { styles } from "../main/OutstandingMainStyle";
// utils
import { modalStyle } from "utils/modalStyle.utils";
// import { StableSort, GetComparator } from "utils/table.utils";
const useStyles = makeStyles(styles);
function OutstandingMultiClear() {
  const classes = useStyles();
  const classesModal = modalStyle();
  const Store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const dStart = moment().add(-7, "days").format("YYYY-MM-DD HH:mm:ss");
  const dEnd = moment().format("YYYY-MM-DD HH:mm:ss");
  const [dateStart, setDateStart] = React.useState(dStart);
  const [dateEnd, setDateEnd] = React.useState(dEnd);

  //---------------------on load
  React.useEffect(() => {
    loadOutstandingMainForm();
  }, []);
  async function loadOutstandingMainForm() {
    const authStore = Store.loginReducer.result;
    if (!authStore) {
      history.push("/login");
    } else {
      if (!Store.outstandingSelectCompanyReducer.result) {
        history.push("/outstanding-main");
      } else {
        const result = Store.outstandingSelectCompanyReducer.result;
        const valuesObj = {
          company_id: result.company_id ? parseInt(result.company_id) : 0,
          date_start: moment(dateStart).format("YYYY-MM-DD HH:mm:ss"),
          date_stop: moment(dateEnd).format("YYYY-MM-DD HH:mm:ss"),
          // company_id: 49,
          // date_start: "2024-01-01 00:00:00",
          // date_stop: "2024-01-31 00:00:00",
        };
        dispatch(checkJWTTOKENAction(history, Store));

        dispatch(GetOutstandingAllAction(history, valuesObj, authStore));
      }
    }
  }
  //---------------On Search Click
  function onSearchClick() {
    loadOutstandingMainForm();
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const firstIdRowInPage = page * rowsPerPage;
      const newSelected = Store.outstandingReducer.result
        .filter(
          (_, index) =>
            index >= firstIdRowInPage && index < firstIdRowInPage + rowsPerPage
        ) // with in rowsPerPage
        .map((n) => n.visitor_record_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleClearOutstandingClick = () => {
    swal({
      title: "เคลียร์รถคงค้าง ?",
      text: "ต้องการเคลียร์รถคงค้าง หรือไม่?",
      icon: "info",
      buttons: {
        cancel: true,
        confirm: "Confirm",
      },
    }).then((willOk) => {
      if (willOk) {
        const company_id =
          Store.outstandingSelectCompanyReducer.result.company_id;
        if (company_id) {
          const valuesObj = { company_id, records_id: selected };
          dispatch(
            MultipliClearOutstandingActopn(
              valuesObj,
              Store.loginReducer.result,
              loadOutstandingMainForm
            )
          );
          setSelected([])
          setPage(0);
        }
      }
    });
  };

  //-----------------Date Handing
  function handdingDateStart(date) {
    if (moment(date) > moment(dateEnd)) {
      const newMoment = moment(date).add(1, "days");
      setDateStart(date);
      setDateEnd(newMoment);
    } else setDateStart(date);
  }
  function handdingDateEnd(date) {
    if (moment(date) < moment(dateStart)) {
      const newMoment = moment(date).subtract(1, "days");
      setDateStart(newMoment);
      setDateEnd(date);
    } else setDateEnd(date);
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - Store.outstandingReducer.result.length
        )
      : 0;

  //   const visibleRows = React.useMemo(
  //     () =>
  //       StableSort(Store.outstandingReducer.result, GetComparator(order, orderBy)).slice(
  //         page * rowsPerPage,
  //         page * rowsPerPage + rowsPerPage
  //       ),
  //     [order, orderBy, page, rowsPerPage]
  //   );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Card>
          <CardHeader
            style={{
              background:
                "linear-gradient(60deg, rgb(209 145 22), rgb(229 178 57))",
            }}
            color="success"
          >
            <h4 className={classes.cardTitleWhite}>
              รถคงค้าง โครงการ{" "}
              {Store.outstandingSelectCompanyReducer.result
                ? Store.outstandingSelectCompanyReducer.result.company_name
                : ""}
            </h4>
            <p className={classes.cardCategoryWhite}>Villager List Table</p>
          </CardHeader>

          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <DateMaterialUi
                  title="Start"
                  selectedDate={dateStart}
                  setSelectedDate={handdingDateStart}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <DateMaterialUi
                  title="End"
                  selectedDate={dateEnd}
                  setSelectedDate={handdingDateEnd}
                />
              </GridItem>
            </GridContainer>
            <br></br>
            <GridContainer>
              <GridItem xs={12} sx={{ m: 5 }}>
                <Button
                  color="primary"
                  size="small"
                  className={classesModal.btnSearch}
                  endIcon={<Icon> search </Icon>}
                  onClick={onSearchClick}
                >
                  <span> ค้นหา</span>
                </Button>
              </GridItem>
            </GridContainer>
            <br></br>

            <EnhancedTableToolbar
              numSelected={selected.length}
              onDeleteClick={handleClearOutstandingClick}
            />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={Store.outstandingReducer.result.length}
                />
                <TableBody>
                  {(rowsPerPage > 0
                    ? Store.outstandingReducer.result.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : Store.outstandingReducer.result
                  ).map((row, index) => {
                    const isItemSelected = isSelected(row.visitor_record_id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row.visitor_record_id)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.visitor_record_id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell>{row.card_code}</TableCell>
                        <TableCell>{row.license_plate}</TableCell>
                        <TableCell>{row.home_address}</TableCell>
                        <TableCell>{row.full_name}</TableCell>
                        <TableCell>{row.parking_in_datetime}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={Store.outstandingReducer.result.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
const mapStateToProps = ({ mainReducer, outstandingReducer }) => ({
  mainReducer,
  outstandingReducer,
});

const mapDispatchToProps = {
  GetOutstandingAllAction,
  checkJWTTOKENAction,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutstandingMultiClear);

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected > 0}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, onDeleteClick } = props;

  const classesModal = modalStyle();
  const Store = useSelector((store) => store);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {`เคลียร์รถคงค้าง โครงการ ${
            Store.outstandingSelectCompanyReducer.result
              ? Store.outstandingSelectCompanyReducer.result.company_name
              : ""
          }`}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          {/* <IconButton onClick={onDeleteClick}>
            <DeleteIcon />
          </IconButton> */}
          <Button
            color="primary"
            size="small"
            startIcon={<Icon> delete </Icon>}
            className={classesModal.btnClear}
            onClick={onDeleteClick}
          >
            <span>Clear</span>
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};
