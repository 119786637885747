export const itemSelectBoxs = [
    {
        value: "STANDARD"
        , text: "Standard"
    },{
        value: "PRO"
        , text: "Pro"
    },{
        value: "PROPLUS"
        , text: "Pro plus"
    }
]

export const itemRadioBoxs = [
    {
        value: "true"
        , text: "จอดฟรีแยกตามวันนั้นๆ"
    },{
        value: "false"
        , text: "จอดฟรีเฉพาะวันแรกที่จอดวันเดียวเท่านั้น"
    }
]